import * as React from 'react'
import { Link } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import moment from 'moment-timezone'
import 'moment/locale/de'  // without this line it didn't work
import { useLocation } from '@reach/router';

// Function to retrieve a small preview of the post's text
const firstParagraph = (post) => {
  // // Find the first text slice of post's body
  const firstTextSlice = post.body.find((slice) => slice.slice_type === 'post_info')
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 100
    const text = firstTextSlice.primary.description.text
    const limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return (
        <p>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</p>
      )
    }
    // If it's shorter than the limit, just show it normally
    return <p>{text}</p>
  }
  // If there are no slices of type 'text', return nothing
  return null
}

// A summary of the Blog Post
const PostSummary = ({ post, id }) => {

  const {data, uid, url, first_publication_date} = post
  const detail = data.body.find(item => {
    return item.slice_type === 'post_info'
  })
  if (!detail) return null
  const {items, primary} = detail
  const firstImgItem = items[0]
  let image = ""
  if (firstImgItem && firstImgItem.image && firstImgItem.image.url) {
    image = firstImgItem.image.url
  }

  const pub_date = moment(first_publication_date).format("DD MMMM YYYY")

  return (
    <div className="col-12 col-md-6 col-lg-4">
			<div className={"overflow-hidden bg-white border h-100 d-flex flex-column " }>
        <a href={url} className="bg-light position-relative overflow-hidden ar16-9">
          {image && <img className="image-cover" src={image} />}
        </a>
				<div className="d-flex flex-fill flex-column justify-content-between p-3 px-4">
          <div className="flex-fill">
            <div className="fs-0875">{pub_date}</div>
            <a className="text-decoration-none text-body" href={url}>
              <div className="fs-1125 mt-2">{post.data.title.text || 'Untitled'}</div>
            </a>
          </div>
				</div>
			</div>
		</div>
  )
}

export const Blog = ({ docs }) => {
  if (!docs) return null

  const blog = docs.blog

  return (
    <div className="mt-4 row g-4">
      {blog.map((post) => (
        <PostSummary post={post} key={post.uid} />
      ))}
      {/* <Pagination pageInfo={docs.pageInfo} /> */}
    </div>
  )
}
