// import * as React from 'react'
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import moment from 'moment';

import { Layout } from '../components/Layout'
import {Blog} from '../components/Blog'
import { components } from '../slices'


const BlogTemplate = ({ data }) => {
  const [blog,setEvents] = useState([])
  
  useEffect( ()=> {
    const docs = data.allPrismicPost || []
    const now = moment()
    const filtered = docs.nodes.filter(item => {
      const data = item.data
      // return (startTime < now)
      return true;
    })
    setEvents(filtered)
  }, [data.allPrismicPost])
  
  if (!data) return null

  const homepage = data.prismicBlog || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const docs = data.allPrismicPost
  docs.blog = blog

  return (
    <Layout activeDocMeta={activeDoc} seo={seo}>
      <div className="custom-header" >
        <div style={{height:"76px"}}></div>
          <div className="container py-5">
              <h3 className="text-white mb-0">{homepage.data.page_title}</h3>
          </div>
      </div>
      <div className="container py-5">
        <Blog docs={docs} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query myBlogQuery($lang: String) {
    prismicBlog(lang: { eq: $lang }) {
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        page_title
      }
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
    }

  allPrismicPost(
    limit: 10
    skip: 0
    #filter: {
			#data:{
        #finish_time:{gte: $now}
      #}
    #}
  ) {
    nodes {
      _previewable
      uid
      url
      first_publication_date
      data {
        title {
          text
        }
        body {
          ... on PrismicPostDataBodyPostInfo {
            id
            slice_label
            slice_type
            items {
              ... on PrismicPostDataBodyPostInfoItem {
                image {
                  url
                }
              }
            }
            primary {
              ... on PrismicPostDataBodyPostInfoPrimary {
                description {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default withPrismicPreview(BlogTemplate)
